import { IconProps } from './types';
import clsx from 'clsx';

const ArrowRight = ({ className, strokeClasses }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={clsx('h-5 w-5', className)}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18 12H6M13 7L18 12L13 7ZM18 12L13 17L18 12Z"
      className={clsx('', strokeClasses)}
    />
  </svg>
);

export default ArrowRight;
