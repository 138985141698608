import { useContext, useState, useEffect } from 'react';
import { AnalyticsContext } from './analytics';

export const useAnalyticsEvent = () => {
  const [inputEventsMap, setInputEventsMap] = useState({});
  const { analytics } = useContext(AnalyticsContext);
  const broadcastEvent = async (name: string) => {
    if (analytics && !inputEventsMap[name]) {
      analytics.track(name);
      setInputEventsMap({ ...inputEventsMap, [name]: true });
    }
  };
  return { broadcastEvent };
};

type ErrorsByFieldMap = { [x: string]: string[] };
export const useAnalyticsErrorEvents = (fieldEnums) => {
  const [errors, setErrors] = useState<Partial<{ [key in keyof typeof fieldEnums]: string }>>({});
  const [reportedErrorsMap, setReportedErrorsMap] = useState<ErrorsByFieldMap>({});
  const { broadcastEvent } = useAnalyticsEvent();

  useEffect(() => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length) {
      errorKeys.forEach(async (k) => {
        const errorAlreadyExists =
          !errors[k] || (k in reportedErrorsMap && reportedErrorsMap[k].includes(errors[k]));
        if (errorAlreadyExists) return;

        setReportedErrorsMap({
          ...reportedErrorsMap,
          [k]: [...(reportedErrorsMap[k] || []), errors[k]],
        });
        await broadcastEvent(`Validation error in ${k}, error: ${errors[k]}`);
      });
    }
  }, [errors]);

  return [errors, setErrors] as const;
};
