import { useContext, createContext, useMemo, useState, useEffect, useCallback } from 'react';
import get from 'lodash/get';
import defaultTranslations from 'public/translation.json';
import React from 'react';
import { useRouter } from 'next/router';
import { TextButton } from 'src/components';
import Markdown from 'markdown-to-jsx';

export type TFunction = (key: string) => string | string[] | { [key: string]: string };

export const TranslationsContext = createContext(defaultTranslations);

export const TranslationsContextProvider = ({ children }) => {
  const [content, setContent] = useState(defaultTranslations);
  const { locale, defaultLocale } = useRouter();

  const loadContent = useCallback(async () => {
    const rawContent =
      locale === defaultLocale
        ? await import(`../../public/translation.json`)
        : await import(`../../public/translation-${locale}.json`);
    setContent(await rawContent.default);
  }, [defaultLocale, locale]);

  useEffect(() => {
    loadContent();
  }, [loadContent, locale]);

  return <TranslationsContext.Provider value={content}>{children}</TranslationsContext.Provider>;
};

export type TranslationsType = typeof defaultTranslations;

export const useTranslations = () => {
  const content = useContext<TranslationsType>(TranslationsContext);

  const t = useMemo(() => (key: string) => get(content, key), [content]);

  return { content, t };
};

interface MDProps {
  children: React.ReactNode;
  className?: string;
}
export const MarkdownFragment = ({ children, className }: MDProps) => {
  return (
    <Markdown
      className={className}
      options={{
        overrides: {
          strong: {
            props: { className: 'font-semibold' },
          },
          b: {
            props: { className: 'font-semibold' },
          },
          a: {
            component: TextButton,
            props: { className: 'inline', as: 'a', target: '_blank' },
          },
        },
      }}
    >
      {children as string}
    </Markdown>
  );
};
