import React from 'react';
import clsx from 'clsx';
import { OptionComponentProps } from './types';
import { IconProps } from 'src/icons/types';

interface Props extends OptionComponentProps {
  Icon?: React.ComponentType<IconProps>;
}
const TextOption = React.forwardRef<HTMLLIElement, Props>(
  ({ label, selected, onClick, Icon, ...props }, ref) => (
    <li
      role="option"
      aria-selected={selected}
      aria-label={label}
      ref={ref}
      className={clsx(
        'w-full h-9 px-4 py-2 text-sm flex flex-row items-center',
        'cursor-pointer list-none hover:bg-blue',
        selected ? 'text-blue-500 font-bold' : 'text-black',
        'focus:outline-none',
      )}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      {...props}
    >
      {Icon && <Icon className="mr-3" />}
      <span className="truncate">{label}</span>
    </li>
  ),
);

TextOption.displayName = 'TextOption';

export default TextOption;
