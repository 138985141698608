import React, { createRef, FormEventHandler } from 'react';
import clsx from 'clsx';
import { Button, ProgressBar } from 'src/components';
import { TFunction, useTranslations } from 'src/renderless/useTranslations';
import { ArrowLeft, ArrowRight } from 'src/icons';

export interface Props {
  headerText: string;
  className?: string;
  children?: React.ReactNode;
  reverse?: boolean;
  progress?: number;
  onSubmit: () => void;
  onBack?: () => void;
  disabled?: boolean;
  nextButtonText?: string;
}

const useContent = (t: TFunction) => ({
  backButton: t('back'),
  nextButton: t('next'),
});

const QuestionForm = ({
  headerText,
  className,
  children,
  reverse,
  onSubmit,
  onBack,
  progress = 1,
  disabled,
  nextButtonText,
  ...props
}: Props) => {
  const { t } = useTranslations();
  const content = useContent(t);
  const buttonBannerRef = createRef();

  const handleSubmit: FormEventHandler<HTMLButtonElement | HTMLFormElement> = (e) => {
    e.preventDefault();
    onSubmit();
  };

  const handleBack: FormEventHandler<HTMLButtonElement | HTMLFormElement> = (e) => {
    e.preventDefault();
    onBack();
  };

  return (
    <>
      <div
        {...props}
        data-testid={props['data-testid'] || 'questionform'}
        className={clsx(
          'bg-white col-span-4 sm:col-span-8 lg:col-start-1 lg:col-end-7 lg:row-start-1 lg:row-end-2 lg:rounded-tr-[20px] lg:h-full pb-12 lg:pb-0 relative',
          className,
          reverse && 'relative overflow-hidden',
        )}
      >
        {reverse && (
          <div className="bg-blue absolute top-0 left-0 h-full z-10 lg:hidden">
            <div
              className={clsx(
                'bg-white h-full w-[298vw] lg:w-[50vw] ml-[-104vw] lg:ml-0 rounded-b-[50%] lg:rounded-none relative overflow-hidden',
              )}
            ></div>
          </div>
        )}
        <div
          className={clsx(
            'grid grid-cols-4 gap-x-5 sm:grid-cols-8 lg:grid-cols-6 lg:gap-x-6 max-w-screen-xl m-auto h-full pb-4 pt-11',
            reverse && 'z-10 pt-0 pb-4',
          )}
        >
          <div className="col-span-full lg:col-span-4 flex flex-col">
            <ProgressBar
              className="w-1/2 mb-3"
              percentageComplete={(progress / 4) * 100}
              statusText={`Step ${progress}`}
              subStatusText="of 4"
            />
            <h2 className="font-semibold text-2xl text-grey-900">{headerText}</h2>
            <form className="pt-9 flex-grow flex flex-col justify-between" onSubmit={handleSubmit}>
              <div className=" mb-16 lg:mb-0">{children}</div>

              <div
                id="button-banner"
                className={clsx(
                  'flex flex-row space-x-4 w-full bg-white border-white lg:mb-10 lg:mt-5',
                )}
              >
                {onBack && (
                  <Button
                    data-testid="form-back"
                    className="w-12 md:w-12 lg:w-12 lg:px-3"
                    outline
                    iconLeft={ArrowLeft}
                    aria-label={content.backButton as string}
                    onClick={handleBack}
                  >
                    {}
                  </Button>
                )}
                <Button
                  data-testid="form-submit"
                  onSubmit={handleSubmit}
                  className="w-full"
                  iconRight={ArrowRight}
                  disabled={disabled}
                >
                  {nextButtonText || content.nextButton}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionForm;
