import { IconProps } from './types';
import clsx from 'clsx';

const QuestionMarkCircle = ({ className, strokeClasses }: IconProps) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx('', className)}
    data-testid="tooltip-icon"
  >
    <path
      d="M7.54233 8.25C8.04558 7.18208 9.40317 6.41667 11 6.41667C13.0258 6.41667 14.6667 7.64775 14.6667 9.16667C14.6667 10.45 13.4952 11.5271 11.9112 11.8314C11.4143 11.9267 11 12.3264 11 12.8333M11 15.5833H11.0092M19.25 11C19.25 12.0834 19.0366 13.1562 18.622 14.1571C18.2074 15.1581 17.5997 16.0675 16.8336 16.8336C16.0675 17.5997 15.1581 18.2074 14.1571 18.622C13.1562 19.0366 12.0834 19.25 11 19.25C9.91659 19.25 8.8438 19.0366 7.84286 18.622C6.84193 18.2074 5.93245 17.5997 5.16637 16.8336C4.40029 16.0675 3.7926 15.1581 3.37799 14.1571C2.96339 13.1562 2.75 12.0834 2.75 11C2.75 8.81196 3.61919 6.71354 5.16637 5.16637C6.71354 3.61919 8.81196 2.75 11 2.75C13.188 2.75 15.2865 3.61919 16.8336 5.16637C18.3808 6.71354 19.25 8.81196 19.25 11Z"
      stroke="#0E0E0E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={clsx('', strokeClasses)}
    />
  </svg>
);

export default QuestionMarkCircle;
