import { useMemo, useState, useRef, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import OptionsList from './OptionsList';
import { DropdownProps } from '../DropDown/DropDown';
import { Options } from '../DropDown/types';
import SearchableButtonInput from './SearchableButtonInput';
import { ChevronDown } from '../../../icons';

export interface SearchableDropdownProps extends DropdownProps {
  filter?: (options: Options, search: string) => Options;
  onSearch?: (value: string) => void;
  isNativeOnMobile?: boolean;
  onKeyboardSelect?: (value: string) => void;
  preventAutoClose?: boolean;
  hideEmptyOption?: boolean;
  large?: boolean;
  IconComponent?: React.ElementType;
  preventIconRotation?: boolean;
}

const defaultFilterFunction = (options: Options, search: string) =>
  search
    ? options.filter((option) => option.label.toLowerCase().startsWith(search.toLowerCase()))
    : options;

const SearchableDropdown = ({
  className,
  disabled,
  IconComponent = ChevronDown,
  label,
  onSelectValue,
  options,
  OptionComponent,
  placeholder,
  value = '',
  error,
  onClose,
  filter = defaultFilterFunction,
  onSearch,
  isNativeOnMobile = true,
  onKeyboardSelect,
  preventAutoClose,
  hideEmptyOption,
  large,
  preventIconRotation,
  ...props
}: SearchableDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const optionsRefs = useRef<any>(null);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (isOpen && !preventAutoClose) {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preventAutoClose]);

  const filteredOptions = useMemo(() => filter(options, search), [options, search, filter]);

  const selectedIndex = useMemo(() => {
    const matchedIndex = filteredOptions.findIndex((option) => option.value === value);
    if (isOpen && matchedIndex === -1) {
      return 0;
    }
    return matchedIndex;
  }, [filteredOptions, isOpen, value]);

  const handleSearch = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;

    setSearch(target.value);
    if (onSearch) {
      onSearch(target.value);
    }
  };

  const keyboardSelectNext = () => {
    if (selectedIndex < filteredOptions.length - 1) {
      if (onKeyboardSelect) {
        onKeyboardSelect(filteredOptions[selectedIndex + 1].value);
      } else {
        onSelectValue(filteredOptions[selectedIndex + 1].value);
      }
    }
  };

  const keyboardSelectPrev = () => {
    if (selectedIndex > 0) {
      if (onKeyboardSelect) {
        onKeyboardSelect(filteredOptions[selectedIndex - 1].value);
      } else {
        onSelectValue(filteredOptions[selectedIndex - 1].value);
      }
    }
  };

  const handleKeyboardInput = (e: React.KeyboardEvent) => {
    const target = optionsRefs.current?.selectedRef;

    switch (e.key) {
      case 'ArrowDown': {
        e.preventDefault();
        if (target && target.parentNode) {
          (target.parentNode as HTMLElement).scrollTop = target.offsetTop;
        }
        keyboardSelectNext();
        break;
      }
      case 'ArrowUp': {
        e.preventDefault();
        if (target && target.parentNode) {
          (target.parentNode as HTMLElement).scrollTop = target.offsetTop - target.offsetHeight * 2;
        }

        keyboardSelectPrev();
        break;
      }
      case 'Enter':
      case 'Escape': {
        if (filteredOptions[selectedIndex]?.value !== value) {
          onSelectValue(filteredOptions[selectedIndex]?.value);
        }
        e.preventDefault();
        closeModal();
        break;
      }
    }
  };

  const toggleModal = (e: React.MouseEvent | React.FocusEvent) => {
    const relatedTarget = e.relatedTarget as HTMLElement;
    if (relatedTarget && optionsRefs.current?.listboxRef === relatedTarget) {
      return;
    }
    e.preventDefault();

    setIsOpen(true);
    if (isOpen) {
      closeModal();
    }
    setIsOpen(!isOpen);
  };

  const closeModal = useCallback(() => {
    setSearch('');

    setIsOpen(false);
    if (onClose) {
      onClose(value);
    }
  }, [onClose, value]);

  const onSelectAndClose = useCallback(
    (newValue: string) => {
      onSelectValue(newValue);
      setSearch('');

      if (!preventAutoClose) {
        setIsOpen(false);
        if (onClose) {
          onClose(newValue);
        }
      }
    },
    [onSelectValue, onClose, preventAutoClose],
  );

  const handleNativeChange = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    onSelectValue(target.value);
    target.blur();
    if (onClose) {
      onClose(target.value);
    }
  };

  const handleNativeBlur = (e: React.FocusEvent<HTMLSelectElement>) => {
    if (onClose) {
      onClose(e.target.value);
    }
  };

  return (
    <>
      {isNativeOnMobile && (
        <div
          className={clsx(
            error ? 'border-red-3 text-red-5' : 'border-grey-3 text-black',
            'md:hidden',
            'border rounded p-1 pr-4',
            'w-full h-12',
            'relative',
            disabled ? 'text-grey-600 bg-grey-300' : 'bg-white text-black',
            className,
          )}
          data-testid={`${props['data-testid']}-native`}
        >
          <select
            aria-invalid={error}
            aria-label={label}
            onChange={handleNativeChange}
            onBlur={handleNativeBlur}
            value={value || ''}
            disabled={disabled}
            className={clsx(
              'w-full h-full focus:outline-none pl-4',
              'appearance-none relative',
              'bg-white bg-no-repeat',
              !value && 'text-grey-600',
              disabled ? 'bg-grey-300' : 'bg-white',
            )}
          >
            <option value="" disabled>
              {placeholder}
            </option>
            {options.map((o) => (
              <option key={o.value} value={o.value}>
                {o.label}
              </option>
            ))}
          </select>
          <span className={clsx('absolute right-4 top-3')}>
            <IconComponent strokeClasses={clsx(disabled && 'stroke-grey-600')} />
          </span>
        </div>
      )}

      <div
        className={clsx(
          isNativeOnMobile ? 'hidden md:block' : 'w-full',
          !isNativeOnMobile && !large && 'md:w-96',
          'relative box-border h-12',
          className,
        )}
        data-testid="desktop-dropdown"
        {...props}
      >
        <SearchableButtonInput
          aria-expanded={isOpen ? true : undefined}
          aria-label={label}
          aria-invalid={error}
          role="listbox"
          disabled={disabled}
          toggleIsOpen={toggleModal}
          placeholder={placeholder}
          option={filteredOptions[selectedIndex]}
          error={error}
          handleSearch={handleSearch}
          isOpen={isOpen}
          handleKeyboardInput={handleKeyboardInput}
          IconComponent={IconComponent}
          preventIconRotation={preventIconRotation}
        />

        {isOpen && (
          <div className="w-full">
            <OptionsList
              large={large}
              aria-label={label}
              className={clsx('absolute top-14 mt-0')}
              onClose={closeModal}
              onSelectValue={onSelectAndClose}
              OptionComponent={OptionComponent}
              options={filteredOptions}
              value={filteredOptions[selectedIndex]?.value}
              ref={optionsRefs}
              hideEmptyOption={hideEmptyOption}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SearchableDropdown;
