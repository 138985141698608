const BottomLeftLine = () => (
  <svg
    width="260"
    height="319"
    viewBox="0 0 260 319"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="bottomLeft"
  >
    <path
      opacity="0.3"
      d="M161.78 419.637C100.899 449.613 23.3249 460.763 -56.5914 457.893C-136.486 455.024 -218.56 438.147 -288.34 412.223C-358.178 386.278 -415.445 351.365 -445.993 312.598C-461.245 293.243 -469.753 273.035 -469.982 252.566C-470.211 232.123 -462.185 211.145 -443.85 190.211C-406.889 148.009 -370.813 126.333 -335.248 115.464C-299.633 104.578 -264.392 104.483 -228.948 105.677C-224.253 105.835 -219.555 106.016 -214.853 106.197C-148.64 108.748 -81.7032 111.326 -12.5448 50.4985C60.1428 -13.433 143.422 -6.83702 194.442 30.8187C219.972 49.6612 237.383 76.2388 241.485 105.646C245.581 135.007 236.445 167.442 208.411 198.14C194.095 213.816 186.311 227.344 183.372 239.292C180.415 251.315 182.402 261.594 187.325 270.604C192.21 279.545 199.952 287.178 208.419 294.076C213.783 298.447 219.568 302.62 225.162 306.654C228.347 308.951 231.469 311.204 234.416 313.422C242.666 319.631 249.629 325.632 253.701 332.026C257.683 338.28 258.877 344.858 255.643 352.545C252.33 360.419 244.296 369.64 229.227 380.732C214.198 391.795 192.372 404.575 161.78 419.637Z"
      stroke="currentColor"
      className="text-blue-200"
      strokeWidth="4"
    />
  </svg>
);

const BottomLeftLine2 = () => (
  <svg
    width="447"
    height="179"
    viewBox="0 0 447 179"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="bottomLeft2"
  >
    <path
      opacity="0.3"
      d="M-184.731 38.7393C-123.444 9.60059 -45.7243 -0.485314 34.1451 3.47982C113.993 7.44387 195.828 25.4441 265.246 52.3219C334.722 79.2223 391.505 114.916 421.519 154.098C436.504 173.661 444.735 193.983 444.683 214.454C444.631 234.898 436.318 255.765 417.698 276.445C380.162 318.136 343.793 339.316 308.082 349.697C272.321 360.093 237.083 359.705 201.658 358.025C196.966 357.803 192.271 357.558 187.572 357.312C121.399 353.854 54.5044 350.359 -15.4812 410.233C-89.0383 473.162 -172.219 465.425 -222.718 427.074C-247.987 407.883 -265.033 381.069 -268.732 351.608C-272.424 322.194 -262.845 289.887 -234.393 259.577C-219.863 244.098 -211.894 230.678 -208.792 218.771C-205.671 206.79 -207.516 196.485 -212.315 187.408C-217.077 178.401 -224.714 170.663 -233.085 163.649C-238.389 159.206 -244.117 154.954 -249.655 150.843C-252.808 148.502 -255.899 146.207 -258.815 143.949C-266.979 137.627 -273.859 131.532 -277.843 125.083C-281.74 118.775 -282.843 112.181 -279.504 104.539C-276.083 96.7104 -267.924 87.6003 -252.704 76.7158C-237.524 65.8603 -215.525 53.3807 -184.731 38.7393Z"
      stroke="currentColor"
      className="text-blue-200"
      strokeWidth="4"
    />
  </svg>
);

export { BottomLeftLine, BottomLeftLine2 };
