import React from 'react';
import clsx from 'clsx';
import { IconProps } from 'src/icons/types';

interface listItem {
  icon: React.ComponentType<IconProps>;
  description: string | React.ReactNode;
}

export interface Props {
  className?: string;
  children?: React.ReactNode;
  listItems: listItem[];
  itemClassName?: string;
  smallGaps?: boolean;
}

const IconList = ({ className, listItems, itemClassName, smallGaps, ...props }: Props) => {
  return (
    <ul {...props} data-testid="iconlist" className={clsx('', className)}>
      {listItems.map((listItem, ix) => (
        <li
          key={ix}
          className={clsx(
            smallGaps ? 'mb-1.5' : 'mb-7',
            'flex flex-row items-center last:mb-0 text-grey-900',
            itemClassName,
          )}
          data-testid="iconlistItem"
        >
          <span className={clsx(smallGaps ? 'mr-2' : 'mr-4', 'self-center')}>
            <listItem.icon className={clsx(smallGaps ? 'w-6' : 'w-8', 'h-8 text-blue-500')} />
          </span>
          {listItem.description}
        </li>
      ))}
    </ul>
  );
};

export default IconList;
