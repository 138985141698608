import { IconProps } from './types';
import clsx from 'clsx';

const ChevronRight = ({ className, strokeClasses }: IconProps) => (
  <svg
    className={clsx('h-3.5 w-3.5 overflow-hidden', className)}
    stroke="currentColor"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={clsx('', strokeClasses)}
      d="M4.66666 2.33331L9.33333 6.99998L4.66666 11.6666"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChevronRight;
