import clsx from 'clsx';
import Image, { ImageProps } from 'next/image';

interface Props {
  assetName?: string;
  children?: React.ReactNode;
  imageProps?: Partial<ImageProps>;
  className?: string;
}

const ImageLayout = ({ assetName, className, children, imageProps = {} }: Props) => (
  <div
    className={clsx(
      'bg-white col-span-4 sm:col-span-8 lg:col-start-1 lg:col-end-7 lg:row-start-1 lg:row-end-2 lg:rounded-tr-[20px] h-full pb-12 lg:pb-0 overflow-y-hidden lg:overflow-y-auto relative',
      className,
    )}
  >
    <div className="grid grid-cols-4 gap-x-5 sm:grid-cols-8 lg:grid-cols-6 lg:gap-x-6 max-w-screen-xl m-auto lg:h-full pb-4  pt-6 sm:pt-10">
      <div className="col-span-full lg:col-span-4 z-10">{children}</div>
    </div>
    {assetName && (
      <>
        <div className="absolute bottom-0 right-0 lg:right-[85px] flex">
          <Image
            layout="fixed"
            src={`/assets/${assetName}`}
            alt="character"
            className="bottom-0"
            {...imageProps}
          />
        </div>
      </>
    )}
  </div>
);

export default ImageLayout;
