/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, createContext, useState, useEffect, useMemo } from 'react';
import cookieCutter from 'cookie-cutter';
import { useRouter } from 'next/router';
import { validate } from 'src/screens/apply/introduce-yourself/validation';
import { FieldEnums } from 'src/screens/apply/introduce-yourself/types';
import { MoveInDateEnums, RentFrequencyEnums } from 'src/screens/apply/your-new-property/types';
import { useAnalyticsEvent } from '../monitoring/analyticsHooks';
import { AnalyticsContext } from '../monitoring/analytics';
import {
  ccjBankruptcyInsolvencyEnum,
  EmploymentTypesEnum,
} from 'src/screens/apply/your-income/types';
import { CreateGuarantidUserInput, useGuarantidUserStore } from './useGuarantidUserStore';
import { AppStateKeys, StripeData, useAppState } from './useAppState';

export type UserInputsType = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  moveInDate?: MoveInDateEnums;
  rentAmount?: string;
  rentFrequency?: RentFrequencyEnums;
  missedRentalPayment?: boolean;
  tenancyDuration?: string;
  employmentStatus?: EmploymentTypesEnum;
  income?: string;
  ccjAmount?: string;
  ccjActive?: boolean;
  ccjBankruptcyInsolvency?: ccjBankruptcyInsolvencyEnum;
  coSigner?: boolean;
  coSignerFullName?: string;
  coSignerEmail?: string;
  ccjPaymentCompletionDate?: string;
  rentalProviderName?: string;
  rentalProviderBranch?: string;
};

export type GuarantidUserStoreInputType = {
  firstName?: string;
  lastName?: string;
  email?: string;
  rentalDetails?: {
    moveInDate: string;
    amount: string;
    duration: number;
    currency?: string;
    frequency: string;
    searchStatus?: string;
  };
  income?: {
    type: string;
    amount?: number;
    currency?: string;
    missedPayments?: boolean;
    incomeMark?: {
      type: string;
      active: boolean;
      amount: number;
      currency?: string;
      date: string;
    };
  };
  cosigner?: {
    name: string;
    email: string;
  };
  rentalAddress?: {
    flatOrBuildingName: string;
    line1: string;
    line2?: string;
    city: string;
    country: string;
    postcode: string;
  };
  rentalProvider?: {
    name?: string;
    email?: string;
    phone?: string;
    branch?: string;
  };
  stripeData?: {
    invoice: string;
  };
};

export type UseUserInputsContextValues = {
  values: Partial<UserInputsType>;
  updateValues: (value: any) => void;
};
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const UserInputsContext = createContext<UseUserInputsContextValues>({
  values: {},
  updateValues: () => undefined,
});

const openRoutes = ['/apply/you'];
const protectedRoutes = ['/apply'];

export const UserInputsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { pathname, replace } = useRouter();
  const { setUserIdentity } = useContext(AnalyticsContext);
  const { broadcastEvent } = useAnalyticsEvent();
  const { createUser } = useGuarantidUserStore();
  const [stripeData, setStripeData] = useAppState<StripeData>(AppStateKeys.STRIPE_DATA, {});
  const [, setIsSubmitted] = useAppState(AppStateKeys.IS_SUBMITTED, false);

  const [values, setValues] = useState<Partial<UserInputsType>>({
    rentFrequency: RentFrequencyEnums.monthly,
    missedRentalPayment: false,
    ccjBankruptcyInsolvency: ccjBankruptcyInsolvencyEnum.no,
    ccjActive: false,
  });

  const isProtectedRouteWithoutRequiredInfo = useMemo(
    () => !values.email && protectedRoutes.find((r) => pathname.includes(r)),
    [pathname, values.email],
  );

  useEffect(() => {
    //Try to get required data from cookie if landing partway through flow
    if (isProtectedRouteWithoutRequiredInfo) {
      try {
        const cookie: CreateGuarantidUserInput = JSON.parse(
          cookieCutter.get(process.env.NEXT_PUBLIC_USER_COOKIE_NAME),
        );

        const isValidCookie =
          !validate(FieldEnums.email, cookie.email) &&
          !validate(FieldEnums.firstName, cookie.firstName) &&
          !validate(FieldEnums.lastName, cookie.lastName);

        if (isValidCookie) {
          setValues({
            ...values,
            ...cookie,
          });
          createUser(cookie);
          return;
        }
      } catch (e) {
        // Gracefully continue
      }

      if (!openRoutes.includes(pathname)) {
        replace(openRoutes[0]);
      }
    }
  }, [replace, pathname, values, isProtectedRouteWithoutRequiredInfo, createUser]);

  const isProtectedRouteWithAlreadySubmittedQuote = useMemo(
    () => protectedRoutes.find((r) => pathname.includes(r)) && stripeData?.invoice,
    [pathname, stripeData],
  );

  useEffect(() => {
    //If user already came through and submitted quote and has come back onto a question screen, reset them to the start
    if (isProtectedRouteWithAlreadySubmittedQuote && !openRoutes.includes(pathname)) {
      replace(openRoutes[0]);
      setStripeData({});
      setIsSubmitted(false);
    }
  }, [isProtectedRouteWithAlreadySubmittedQuote, pathname, replace, setIsSubmitted, setStripeData]);

  useEffect(() => {
    if (values.email && pathname !== '/apply/you') setUserIdentity(values.email);
  }, [values, pathname, setUserIdentity]);

  const updateValues = (newValue: Partial<UserInputsType>) => {
    const extractedKey = Object.keys(newValue)[0];
    broadcastEvent(`Changed ${extractedKey}`);
    setValues({
      ...values,
      ...newValue,
    });
  };

  if (isProtectedRouteWithoutRequiredInfo && !openRoutes.includes(pathname)) {
    return null;
  }
  return (
    <UserInputsContext.Provider value={{ values, updateValues }}>
      {children}
    </UserInputsContext.Provider>
  );
};

export const useUserInputs = () => useContext<UseUserInputsContextValues>(UserInputsContext);
