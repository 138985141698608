import React from 'react';
import clsx from 'clsx';
import Head from 'next/head';
import { Breadcrumb, Grid } from 'src/components';
import { useTranslations, TFunction } from 'src/renderless/useTranslations';
export interface Props {
  className?: string;
  children?: React.ReactNode;
  title?: string;
  activeCrumb?: number;
}

const useContent = (t: TFunction) => ({
  breadcrumbs: t('breadcrumbs') as string[],
});

const QuestionTemplate = ({ className, children, title, activeCrumb = 0, ...props }: Props) => {
  const { t } = useTranslations();
  const content = useContent(t);
  return (
    <div className="h-mainsm lg:h-main overflow-y-auto overflow-x-hidden">
      <Head>
        <title>Guarantid | {title} </title>
      </Head>

      <main
        className={clsx(`w-screen h-full flex flex-col`, className)}
        {...props}
        data-testid="QuestionTemplate"
      >
        <div className="bg-blue py-3 h-breadcrumb flex-none">
          <Grid>
            <div className=" col-span-4 sm:col-span-8 lg:col-span-6">
              <Breadcrumb
                crumbs={content.breadcrumbs.map((bc, ix) => ({
                  title: bc,
                  completed: ix < activeCrumb,
                  active: ix === activeCrumb && !(content.breadcrumbs.length - 1 === ix),
                }))}
              />
            </div>
          </Grid>
        </div>
        <Grid className={`lg:bg-blue lg:pl-0 lg:border-l-[1.5rem] border-white flex-grow`}>
          {children}
        </Grid>
      </main>
    </div>
  );
};

export default QuestionTemplate;
