import React from 'react';
import clsx from 'clsx';

export interface Props {
  className?: string;
  children?: React.ReactNode;
}

const Card = ({ className, children, ...props }: Props) => {
  return (
    <div
      {...props}
      data-testid="card"
      className={clsx('rounded bg-white shadow-3xl p-7', className)}
    >
      {children}
    </div>
  );
};

export default Card;
