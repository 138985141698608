import React from 'react';
import clsx from 'clsx';
import { LineArt } from '../..';

export interface Props {
  headerText: string;
  description: React.ReactNode | string;
  className?: string;
  children?: React.ReactNode;
  reverse?: boolean;
  hideBase?: boolean;
}

const QuestionAside = ({
  className,
  children,
  reverse,
  headerText,
  description,
  hideBase,
  ...props
}: Props) => {
  return (
    <aside
      {...props}
      data-testid="questionaside"
      className={clsx(
        'col-span-4 sm:col-span-8 lg:col-start-7 lg:col-end-13 lg:h-auto bg-white relative',
        reverse &&
          'row-start-2 row-end-3 lg:row-start-1 lg:row-end-2 -mt-6 pt-6 pb-6 lg:mt-0 lg:pt-0',
        className,
      )}
    >
      <div className="absolute top-0 left-0 h-full">
        <div
          className={clsx(
            !reverse && 'rounded-b-[50%] lg:rounded-none',
            'bg-blue h-full w-[200vw] lg:w-[50vw] ml-[-55vw] lg:ml-0  relative overflow-hidden lg:overflow-visible',
            reverse && 'rounded-b-none ',
          )}
        >
          <span
            className={clsx(
              'absolute  bottom-[1.4rem] lg:-top-12 lg:right-0',
              !reverse
                ? 'right-[calc(48vw+0.6rem)] sm:right-[46vw]'
                : 'right-[48vw] sm:right-[48vw]',
              reverse && 'top-12',
            )}
          >
            <LineArt name="topRight" />
          </span>
          <span
            className={clsx(
              'absolute  bottom-0 lg:-top-12 lg:right-0',
              !reverse
                ? 'right-[calc(48vw+0.6rem)] sm:right-[46vw]'
                : 'right-[36vw] sm:right-[47vw]',
              reverse && 'top-16  sm:top-8',
            )}
          >
            <LineArt name="topRight2" />
          </span>
          <span className="hidden lg:block absolute bottom-0 -left-6">
            <LineArt name="bottomLeft" />
          </span>
          <span className="hidden lg:block absolute bottom-0 -left-6">
            <LineArt name="bottomLeft2" />
          </span>
        </div>
      </div>
      <div
        className={clsx(
          'grid grid-cols-4 gap-x-5 sm:grid-cols-8 lg:grid-cols-6 lg:gap-x-6 max-w-screen-xl m-auto relative',
          reverse ? 'sm:mt-10 mb-0' : 'mt-10 mb-6',
          reverse && 'mt-0 pt-0 mb-0',
        )}
      >
        <div className="col-span-5 sm:col-span-8 lg:col-span-5 lg:col-start-2">
          {headerText && (
            <h1 className="text-2xl lg:text-3xl font-serif mb-4 text-grey-900">{headerText}</h1>
          )}
          {description && <p className="lg:text-lg mb-10 text-grey-800">{description}</p>}
          <div className="grid grid-cols-5 sm:grid-cols-8 lg:grid-cols-5">{children}</div>
        </div>
      </div>
      {reverse && !hideBase && (
        <div className="lg:hidden bg-blue absolute h-7 -bottom-7 -left-5 w-screen"></div>
      )}
    </aside>
  );
};

export default QuestionAside;
