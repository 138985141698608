export type Ok<T> = { type: 'Ok'; value: T };
export type Err = { type: 'Error'; message: string };

export const mkOk = <T>(arg: T): Ok<T> => ({
  type: 'Ok',
  value: arg,
});

export const mkError = (message: string): Err => ({
  type: 'Error',
  message,
});

export type StringMap<T> = { [k: string]: T };
