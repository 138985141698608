import { DetailedHTMLProps } from 'react';
import clsx from 'clsx';
import { Option } from '../DropDown/types';
import { ButtonHTMLAttributes } from 'react';
import { ChevronDown } from '../../../icons';

export interface ButtonContentsProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  placeholder?: string;
  option?: Option;
  error?: boolean;
  handleSearch?: (e: React.ChangeEvent) => void;
  isOpen?: boolean;
  handleKeyboardInput?: (e: React.KeyboardEvent) => void;
  toggleIsOpen: (e: React.FocusEvent | React.MouseEvent) => void;
  IconComponent?: React.ElementType;
  preventIconRotation?: boolean;
}

const SearchableButtonInput = ({
  placeholder,
  option,
  error,
  handleSearch,
  isOpen,
  handleKeyboardInput,
  toggleIsOpen,
  disabled,
  IconComponent = ChevronDown,
  preventIconRotation,
  ...props
}: ButtonContentsProps) => (
  <button
    {...props}
    data-testid="searchable-dropdown-button"
    onClick={toggleIsOpen}
    disabled={isOpen || disabled}
    className={clsx(
      'w-full h-12 bg-white px-4 py-3 text-sm',
      error ? 'border-red-400 text-red-800' : 'border-grey-500',
      'border rounded',
      disabled
        ? 'text-grey-600 bg-grey-300 border-grey-600'
        : 'text-black hover:border-blue-700 focus:border-blue-700',
    )}
  >
    <div
      className={clsx(
        'absolute left-0 right-0 top-1 bottom-1 px-4',
        'flex flex-row flex-no-wrap items-center',
      )}
    >
      {isOpen ? (
        <input
          data-testid="searchable-dropdown-search-input"
          autoFocus
          role="combobox"
          aria-expanded="true"
          aria-controls="dropdown-options"
          aria-activedescendant={option?.value}
          autoComplete="off"
          type="text"
          onKeyDown={handleKeyboardInput}
          onChange={handleSearch}
          className="h-full w-full focus:outline-none text-black opacity-100"
          placeholder={option?.label || placeholder}
          onBlur={toggleIsOpen}
        />
      ) : (
        <>
          {option?.icon && <option.icon className="mr-3" />}
          <div className={clsx(!option && 'text-grey-600 truncate')}>
            {option?.label || placeholder}
          </div>
        </>
      )}
      <span
        className={clsx('absolute right-4 top-2.5', isOpen && !preventIconRotation && 'rotate-180')}
      >
        <IconComponent />
      </span>
    </div>
  </button>
);

export default SearchableButtonInput;
