import { IconProps } from './types';
import clsx from 'clsx';

const FormTick = ({ className, strokeClasses = 'stroke-white' }: IconProps) => (
  <svg
    width="10"
    height="8"
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    data-testid="form-tick"
  >
    <path
      d="M8.88897 1.5L3.92369 6.46528L1.66675 4.20833"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={strokeClasses}
    />
  </svg>
);

export default FormTick;
