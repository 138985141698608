import { useContext, createContext, useState, useCallback } from 'react';
import axios from 'axios';
import * as Sentry from '@sentry/nextjs';

export type CreateGuarantidUserInput = {
  firstName: string;
  lastName: string;
  email: string;
  rentalDetails?: {
    moveInDate: string;
    amount: string;
    duration: number;
    currency?: string;
    frequency: string;
    searchStatus?: string;
  };
};

export type UpdateGuarantidUserInput = {
  profile: {
    phone: string;
  };
};

export type UpdateGuarantidQuoteInput = {
  rentalDetails?: {
    moveInDate: string;
    amount: string;
    duration: number;
    currency?: string;
    frequency: string;
    searchStatus?: string;
  };
  income?: {
    type: string;
    amount?: number;
    currency?: string;
    missedPayments?: boolean;
    incomeMark?: {
      type: string;
      active: boolean;
      amount: number;
      currency?: string;
      date: string;
    };
  };
  cosigner?: {
    name: string;
    email: string;
  };
  rentalAddress?: {
    flatOrBuildingName: string;
    line1: string;
    line2?: string;
    city: string;
    country: string;
    postcode: string;
  };
  rentalProvider?: {
    name?: string;
    email?: string;
    phone?: string;
    branch?: string;
  };
  stripeData?: {
    invoice: string;
  };
};

export type PostPaymentInput = {
  stripeData: {
    invoice: string;
  };
  rentalDetails: {
    moveInDateActual: string;
  };
  rentalAddress: {
    flatOrBuildingName: string;
    line1: string;
    line2?: string;
    city: string;
    country: string;
    postcode: string;
  };
  rentalProvider: {
    name: string;
    email: string;
    phone: string;
  };
};
interface SubmitSuccessResponse {
  eligible: true;
  duration: number;
  product?: string;
  price?: string;
  customer?: string;
  invoiceItem?: string;
  invoice?: string;
}
interface SubmitFailureResponse {
  eligible: false;
}

type SubmitResponseData = SubmitSuccessResponse | SubmitFailureResponse;

export type GuarantidUserStoreContextType = {
  isLoggedIn: boolean;
  createUser: (params: CreateGuarantidUserInput) => Promise<void>;
  updateQuote: (params: UpdateGuarantidQuoteInput) => Promise<void>;
  updateProfile: (params: UpdateGuarantidUserInput) => Promise<void>;
  submitQuote: () => Promise<SubmitResponseData>;
  postPayment: (params: PostPaymentInput) => Promise<void>;
};
export const UseGuarantidUserStoreContext = createContext<GuarantidUserStoreContextType>({
  isLoggedIn: false,
  createUser: () => undefined,
  updateQuote: () => undefined,
  updateProfile: () => undefined,
  submitQuote: () => undefined,
  postPayment: () => undefined,
});

export const GuarantidUserStoreContextProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const createUser = useCallback(async (data: CreateGuarantidUserInput) => {
    try {
      const result = await axios.post(
        `${process.env.NEXT_PUBLIC_GUARANTID_USER_STORE_URL}/user/v1.0.0`,
        data,
        {
          withCredentials: true,
        },
      );

      if (!result.data?.ok) {
        throw new Error();
      }
      setIsLoggedIn(true);
    } catch (e) {
      Sentry.captureException(e);
      throw e;
    }
  }, []);

  const updateQuote = useCallback(async (data: UpdateGuarantidQuoteInput) => {
    try {
      const result = await axios.patch(
        `${process.env.NEXT_PUBLIC_GUARANTID_USER_STORE_URL}/quote/v1.0.0`,
        data,
        {
          withCredentials: true,
        },
      );

      if (!result.data?.ok) {
        throw new Error();
      }
    } catch (e) {
      Sentry.captureException(e);
      if (e?.response?.status === 401) {
        setIsLoggedIn(false);
      }
      throw e;
    }
  }, []);

  const updateProfile = useCallback(async (data: UpdateGuarantidUserInput) => {
    try {
      const result = await axios.patch(
        `${process.env.NEXT_PUBLIC_GUARANTID_USER_STORE_URL}/user/v1.0.0`,
        data,
        {
          withCredentials: true,
        },
      );

      if (!result.data?.ok) {
        throw new Error();
      }
    } catch (e) {
      Sentry.captureException(e);
      if (e?.response?.status === 401) {
        setIsLoggedIn(false);
      }
      throw e;
    }
  }, []);

  const submitQuote = useCallback(async () => {
    try {
      const result = await axios.post<SubmitResponseData>(
        `${process.env.NEXT_PUBLIC_GUARANTID_USER_STORE_URL}/quote/v1.0.0/submit-quote`,
        {},
        {
          withCredentials: true,
        },
      );
      return result.data;
    } catch (e) {
      Sentry.captureException(e);
      throw e;
    }
  }, []);

  const postPayment = useCallback(async (data: PostPaymentInput) => {
    try {
      const result = await axios.patch(
        `${process.env.NEXT_PUBLIC_GUARANTID_USER_STORE_URL}/quote/v1.0.0/post-payment`,
        data,
        {
          withCredentials: true,
        },
      );
      if (!result.data?.ok) {
        throw new Error();
      }
    } catch (e) {
      Sentry.captureException(e);
      if (e?.response?.status === 401) {
        setIsLoggedIn(false);
      }
      throw e;
    }
  }, []);

  return (
    <UseGuarantidUserStoreContext.Provider
      value={{ isLoggedIn, createUser, updateQuote, updateProfile, submitQuote, postPayment }}
    >
      {children}
    </UseGuarantidUserStoreContext.Provider>
  );
};

export const useGuarantidUserStore = () => useContext(UseGuarantidUserStoreContext);
