export enum FieldEnums {
  employmentStatus = 'employmentStatus',
  income = 'income',
  missedRentalPayment = 'missedRentalPayment',
  ccjBankruptcyInsolvency = 'ccjBankruptcyInsolvency',
  ccjActive = 'ccjActive',
  ccjAmount = 'ccjAmount',
  ccjPaymentCompletionDate = 'ccjPaymentCompletionDate',
  coSigner = 'coSigner',
  coSignerFullName = 'coSignerFullName',
  coSignerEmail = 'coSignerEmail',
}

export enum EmploymentTypesEnum {
  employed = 'employed',
  selfEmployed = 'selfEmployed',
  student = 'student',
  firstYearStudent = 'firstYearStudent',
  unemployed = 'unemployed',
  disabilityBenefits = 'disabilityBenefits',
  otherBenefitType = 'otherBenefitType',
  other = 'other',
}

export const EmploymentTypesMap = {
  [EmploymentTypesEnum.employed]: 'EMPLOYED',
  [EmploymentTypesEnum.selfEmployed]: 'SELF_EMPLOYED',
  [EmploymentTypesEnum.student]: 'STUDENT',
  [EmploymentTypesEnum.firstYearStudent]: 'FIRST_YEAR_STUDENT',
  [EmploymentTypesEnum.unemployed]: 'UNEMPLOYED',
  [EmploymentTypesEnum.disabilityBenefits]: 'DISABILITY_BENEFITS',
  [EmploymentTypesEnum.otherBenefitType]: 'OTHER_BENEFIT_TYPE',
  [EmploymentTypesEnum.other]: 'OTHER',
};

export type EmploymentType = keyof typeof EmploymentTypesEnum;

export enum ccjBankruptcyInsolvencyEnum {
  no = 'no',
  ccj = 'ccj',
  bankruptcy = 'bankruptcy',
  insolvency = 'insolvency',
}

export type CcjBankruptcyInsolvencyType = keyof typeof ccjBankruptcyInsolvencyEnum;

export enum yesNoEnum {
  yes = 'yes',
  no = 'no',
}
