import { FieldEnums } from './types';
import emailValidator from 'email-validator';
import { ValidationRule, validationRules } from 'src/validation';

const emailRules: ValidationRule[] = [
  {
    rule: validationRules.required,
    errorMessage: 'validation.email.required',
  },
  {
    rule: (v) => emailValidator.validate(v as string),
    errorMessage: 'validation.email.invalid',
  },
];

const nameRules = (key: string): ValidationRule[] => [
  {
    rule: validationRules.required,
    errorMessage: `validation.${key}.required`,
  },
  {
    rule: validationRules.unicodePattern,
    errorMessage: `validation.${key}.invalid`,
  },
  {
    rule: validationRules.maxLength(64),
    errorMessage: `validation.${key}.invalid`,
  },
];

const rules = {
  [FieldEnums.email]: (v: string) => emailRules.find(({ rule }) => !rule(v)),
  [FieldEnums.firstName]: (v: string) =>
    nameRules(FieldEnums.firstName).find(({ rule }) => !rule(v)),
  [FieldEnums.lastName]: (v: string) => nameRules(FieldEnums.lastName).find(({ rule }) => !rule(v)),
};

export const validate = (key: FieldEnums, value: string) => rules[key](value);

export const validateAll = (values: { [key in keyof typeof FieldEnums]: string }) =>
  (Object.keys(rules) as FieldEnums[]).reduce((res, cur) => {
    const result = rules[cur](values[cur]);
    return result ? { ...res, [cur]: result } : res;
  }, {});
