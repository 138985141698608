import clsx from 'clsx';
import { IconProps } from './types';

const ArrowLeft = ({ className, strokeClasses }: IconProps) => (
  <svg
    className={clsx('h-5 w-5', className)}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 12H18M11 17L6 12L11 17ZM6 12L11 7L6 12Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={clsx(strokeClasses)}
    />
  </svg>
);

export default ArrowLeft;
