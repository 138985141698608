export enum FieldEnums {
  moveInDate = 'moveInDate',
  tenancyDuration = 'tenancyDuration',
  rentAmount = 'rentAmount',
  rentFrequency = 'rentFrequency',
}

export enum AgentFieldEnums {
  agentName = 'agentName',
  branch = 'branch',
}

export enum MoveInDateEnums {
  ASAP = 'ASAP',
  within1Month = 'within 1 month',
  within3Months = 'within 3 months',
  quote = 'quote',
}

export const MoveInDatesMap = {
  [MoveInDateEnums.ASAP]: 'ASAP',
  [MoveInDateEnums.within1Month]: 'WITHIN_1_MONTH',
  [MoveInDateEnums.within3Months]: 'WITHIN_3_MONTHS',
  [MoveInDateEnums.quote]: 'QUOTE',
};

export enum RentFrequencyEnums {
  weekly = 'weekly',
  monthly = 'monthly',
  quarterly = 'quarterly',
  annually = 'annually',
}

export type RentFrequencyType = keyof typeof RentFrequencyEnums;
