import '../styles/globals.css';
import type { AppProps } from 'next/app';
import { UserInputsContextProvider } from 'src/renderless/useUserInputs';
import { Header } from 'src/components';
import { TranslationsContextProvider } from 'src/renderless/useTranslations';
import AnalyticsProvider from 'src/monitoring/analytics';
import { AppStateContextProvider } from 'src/renderless/useAppState';
import { GuarantidUserStoreContextProvider } from 'src/renderless/useGuarantidUserStore';

function MyApp({ Component, pageProps, router }: AppProps) {
  return (
    <TranslationsContextProvider>
      <AnalyticsProvider path={router.route}>
        <GuarantidUserStoreContextProvider>
          <AppStateContextProvider>
            <UserInputsContextProvider>
              <div className="h-screen w-screen overflow-y-hidden">
                <Header />
                <Component {...pageProps} />
              </div>
            </UserInputsContextProvider>
          </AppStateContextProvider>
        </GuarantidUserStoreContextProvider>
      </AnalyticsProvider>
    </TranslationsContextProvider>
  );
}

export default MyApp;
