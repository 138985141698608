import { PostcoderAddress } from './postcoderApi';
import UKAddressAutoComplete from './UKAddressAutoComplete';

interface Props {
  onSelectAddress: (address: PostcoderAddress) => void;
  label: string;
  placeholder: string;
  className?: string;
}

const AddressAutoComplete = (props: Props) => <UKAddressAutoComplete {...props} />;

export default AddressAutoComplete;
