import React, { ElementType } from 'react';
import clsx from 'clsx';
import { IconProps } from 'src/icons/types';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  as?: ElementType;
  href?: string;
  className?: string;
  iconLeft?: React.ComponentType<IconProps>;
  iconRight?: React.ComponentType<IconProps>;
  children?: React.ReactNode;
  outline?: boolean;
}

const ButtonBase = ({
  as: Component = 'button',
  className,
  children,
  iconLeft: IconLeft,
  iconRight: IconRight,
  ...props
}: ButtonProps) => {
  return (
    <Component
      {...props}
      data-testid="button"
      className={clsx(
        'px-3 md:px-8 py-3 h-12',
        'flex flex-row items-center justify-center lg:w-min whitespace-nowrap',
        'focus:outline-none focus-visible:ring-2 font-semibold',
        'transition duration-100 ease-in-out',
        className,
      )}
    >
      {IconLeft && (
        <span className="self-center">
          <IconLeft className="h-5 w-5 text-inherit" strokeClasses="" />
        </span>
      )}
      {children && <div className="mx-3">{children}</div>}
      {IconRight && (
        <span className="self-center">
          <IconRight className="h-5 w-5" />
        </span>
      )}
    </Component>
  );
};

export default ButtonBase;
