import React, { ElementType } from 'react';
import clsx from 'clsx';
import { IconProps } from 'src/icons/types';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  as?: ElementType;
  iconLeft?: React.ComponentType<IconProps>;
  iconRight?: React.ComponentType<IconProps>;
  children: React.ReactNode;
}

export interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  as: 'a';
  href: string;
  iconLeft?: React.ComponentType<IconProps>;
  iconRight?: React.ComponentType<IconProps>;
  children: React.ReactNode;
}

const TextButton = ({
  as: Component = 'button',
  className,
  children,
  iconLeft: IconLeft,
  iconRight: IconRight,
  ...props
}: LinkProps | ButtonProps) => {
  return (
    <Component
      {...props}
      data-testid="text-button"
      className={clsx(
        'font-semibold text-sm text-blue-600',
        'hover:text-blue-500 hover:underline',
        'focus-visible:text-blue-700 active:text-blue-700',
        (IconLeft || IconRight) && 'flex flex-row items-center justify-center w-min whitespace-nowrap',
        'focus:outline-none focus-visible:ring-2',
        'transition duration-100 ease-in-out',
        className,
      )}
    >
      {IconLeft && (
        <span className="self-center mr-2">
          <IconLeft className="h-6 w-6 text-inherit" strokeClasses="" />
        </span>
      )}
      {children}
      {IconRight && (
        <span className="self-center ml-2">
          <IconRight className="h-6 w-6" />
        </span>
      )}
    </Component>
  );
};

export default TextButton;
