import { IconProps } from './types';
import clsx from 'clsx';

const LightningBolt = ({ className, strokeClasses }: IconProps) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx('h-5 w-5', className)}
  >
    <path
      d="M17.333 13.3333V4L5.33301 18.6667H14.6663V28L26.6663 13.3333H17.333Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={clsx('', strokeClasses)}
    />
  </svg>
);

export default LightningBolt;
