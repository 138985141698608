import axios from 'axios';
import * as Sentry from '@sentry/nextjs';

export interface PostcoderAutocompleteResults {
  id: string;
  count: number;
  summaryline: string;
  locationsummary: string;
}

export interface PostcoderAddress {
  buildingname?: string;
  subbuildingname?: string;
  addressline1?: string;
  addressline2?: string;
  postcode?: string;
  posttown?: string;
  country?: string;
  county?: string;
  premise?: string;
  street?: string;
  summaryline?: string;
  uniquedeliverypointreferencenumber?: string;
  number?: string;
}

const PostcoderFindUri = 'https://ws.postcoder.com/pcw/autocomplete/find?';
const PostcoderRetreiveUri = 'https://ws.postcoder.com/pcw/autocomplete/retrieve?';

export const getAddressList = async ({
  query,
  pathfilter = '',
}: {
  query: string;
  pathfilter?: string;
}): Promise<PostcoderAutocompleteResults[]> => {
  try {
    const uri = `${PostcoderFindUri}${new URLSearchParams({
      query,
      pathfilter,
      apikey: process.env.NEXT_PUBLIC_POSTCODER_API_KEY as string,
      country: 'UK',
      format: 'json',
    }).toString()}`;

    const result = await axios.get(uri);

    return result.data;
  } catch (e) {
    Sentry.captureException(e, {
      tags: { category: 'Postcoder' },
    });
    return [];
  }
};

export const getAddress = async (id: string, query: string): Promise<PostcoderAddress> => {
  try {
    const uri = `${PostcoderRetreiveUri}${new URLSearchParams({
      id,
      query,
      apikey: process.env.NEXT_PUBLIC_POSTCODER_API_KEY as string,
      country: 'UK',
      format: 'json',
      lines: '2',
      addtags: ['country', 'udprn'],
    } as any).toString()}`;

    const result = await axios.get(uri);

    return result.data[0];
  } catch (e) {
    Sentry.captureException(e, {
      tags: { category: 'Postcoder' },
    });
    return {};
  }
};
