const TopRightLine = () => (
  <>
    <svg
      width="124"
      height="264"
      viewBox="0 0 124 264"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="topRight"
      className="block sm:hidden"
    >
      <path
        opacity="0.3"
        d="M56.873 237.042C31.6015 218.89 15.9261 191.075 8.19279 160.711C0.458833 130.345 0.709236 97.5702 7.16293 69.7291C13.6351 41.8084 26.2459 19.2401 42.8608 8.79635C51.113 3.60915 60.38 1.38795 70.5157 2.96798C80.6872 4.55362 91.8893 9.99382 103.9 20.412C128.44 41.6987 147.135 51.1832 162.869 53.863C178.673 56.5548 191.192 52.3265 203.16 46.9176C205.753 45.7457 208.308 44.5262 210.86 43.308C220.173 38.8631 229.449 34.4357 240.396 32.4012C254.187 29.8384 270.685 31.1048 292.855 41.6397C336.576 62.4158 346.176 114.323 334.258 158.19C328.318 180.055 317.088 199.587 302.419 211.876C287.815 224.109 269.826 229.173 249.963 222.333C228.856 215.065 212.377 217.075 197.98 223.055C187.484 227.414 178.027 233.938 168.808 240.298C165.559 242.54 162.338 244.762 159.112 246.861C146.606 255 133.754 261.526 117.8 261.627C101.839 261.728 82.4277 255.396 56.873 237.042Z"
        stroke="currentColor"
        className="text-blue-200"
        strokeWidth="4"
      />
    </svg>

    <svg
      width="320"
      height="264"
      viewBox="0 0 320 264"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="hidden sm:block lg:hidden"
    >
      <path
        opacity="0.3"
        d="M56.873 237.042C31.6015 218.89 15.9261 191.075 8.19279 160.711C0.458833 130.345 0.709236 97.5702 7.16293 69.7291C13.6351 41.8084 26.2459 19.2401 42.8608 8.79635C51.113 3.60915 60.38 1.38795 70.5157 2.96798C80.6872 4.55362 91.8893 9.99382 103.9 20.412C128.44 41.6987 147.135 51.1832 162.869 53.863C178.673 56.5548 191.192 52.3265 203.16 46.9176C205.753 45.7457 208.308 44.5262 210.86 43.308C220.173 38.8631 229.449 34.4357 240.396 32.4012C254.187 29.8384 270.685 31.1048 292.855 41.6397C336.576 62.4158 346.176 114.323 334.258 158.19C328.318 180.055 317.088 199.587 302.419 211.876C287.815 224.109 269.826 229.173 249.963 222.333C228.856 215.065 212.377 217.075 197.98 223.055C187.484 227.414 178.027 233.938 168.808 240.298C165.559 242.54 162.338 244.762 159.112 246.861C146.606 255 133.754 261.526 117.8 261.627C101.839 261.728 82.4277 255.396 56.873 237.042Z"
        stroke="currentColor"
        className="text-blue-200"
        strokeWidth="4"
      />
    </svg>
    <svg
      width="300"
      height="322"
      viewBox="0 0 300 322"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="hidden lg:block"
    >
      <path
        opacity="0.3"
        d="M82.7918 283.282C45.3652 256.4 22.1922 215.238 10.7696 170.389C-0.653499 125.537 -0.283806 77.139 9.24885 36.0154C18.8 -5.18795 37.4494 -38.6982 62.2138 -54.2646C74.5408 -62.0131 88.4103 -65.3408 103.57 -62.9775C118.766 -60.6087 135.414 -52.4956 153.185 -37.0801C189.248 -5.79863 216.61 8.03939 239.528 11.9427C262.517 15.858 280.736 9.72339 298.29 1.7894C302.07 0.0811582 305.807 -1.70271 309.549 -3.48917C323.273 -10.0407 337.071 -16.6271 353.319 -19.6468C373.841 -23.4607 398.329 -21.5526 431.116 -5.97208C496.073 24.8952 510.168 101.913 492.568 166.692C483.787 199.013 467.173 227.951 445.395 246.194C423.683 264.382 396.863 271.948 367.267 261.757C336.425 251.137 312.407 254.079 291.415 262.798C276.065 269.174 262.268 278.692 248.721 288.038C243.92 291.35 239.151 294.64 234.355 297.761C215.898 309.772 196.792 319.498 173.034 319.649C149.268 319.8 120.501 310.367 82.7918 283.282Z"
        stroke="currentColor"
        className="text-blue-200"
        strokeWidth="4"
      />
    </svg>
  </>
);

const TopRightLine2 = () => (
  <>
    <svg
      width="143"
      height="278"
      viewBox="0 0 143 278"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="topRight2"
      className="block sm:hidden"
    >
      <path
        opacity="0.3"
        d="M430.503 284.256C389.272 304.557 336.689 312.126 282.46 310.179C228.254 308.232 172.565 296.781 125.224 279.193C77.8253 261.584 39.05 237.919 18.4044 211.718C8.10377 198.646 2.39864 185.051 2.24518 171.325C2.09198 157.626 7.46555 143.522 19.8391 129.395C44.8779 100.806 69.2869 86.1523 93.3219 78.8066C117.408 71.4453 141.255 71.3752 165.327 72.1858C168.52 72.2934 171.717 72.4166 174.917 72.54C219.892 74.2745 265.604 76.0373 312.784 34.5414C361.939 -8.69228 418.179 -4.19391 452.594 21.2065C469.821 33.9213 481.539 51.8318 484.298 71.6127C487.051 91.3469 480.924 113.195 461.991 133.926C452.226 144.619 446.863 153.902 444.832 162.162C442.782 170.496 444.161 177.643 447.577 183.894C450.955 190.077 456.29 195.324 462.065 200.029C465.72 203.008 469.683 205.866 473.495 208.616C475.654 210.174 477.765 211.697 479.745 213.187C485.347 217.403 489.993 221.421 492.691 225.657C495.3 229.754 496.064 234.002 493.96 239.004C491.777 244.192 486.432 250.372 476.225 257.885C466.059 265.368 451.269 274.031 430.503 284.256Z"
        stroke="currentColor"
        className="text-blue-200"
        strokeWidth="4"
      />
    </svg>
    <svg
      width="339"
      height="278"
      viewBox="0 0 339 278"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="hidden sm:block lg:hidden"
    >
      <path
        opacity="0.3"
        d="M430.503 284.256C389.272 304.557 336.689 312.126 282.46 310.179C228.254 308.232 172.565 296.781 125.224 279.193C77.8253 261.584 39.05 237.919 18.4044 211.718C8.10377 198.646 2.39864 185.051 2.24518 171.325C2.09198 157.626 7.46555 143.522 19.8391 129.395C44.8779 100.806 69.2869 86.1523 93.3219 78.8066C117.408 71.4453 141.255 71.3752 165.327 72.1858C168.52 72.2934 171.717 72.4166 174.917 72.54C219.892 74.2745 265.604 76.0373 312.784 34.5414C361.939 -8.69228 418.179 -4.19391 452.594 21.2065C469.821 33.9213 481.539 51.8318 484.298 71.6127C487.051 91.3469 480.924 113.195 461.991 133.926C452.226 144.619 446.863 153.902 444.832 162.162C442.782 170.496 444.161 177.643 447.577 183.894C450.955 190.077 456.29 195.324 462.065 200.029C465.72 203.008 469.683 205.866 473.495 208.616C475.654 210.174 477.765 211.697 479.745 213.187C485.347 217.403 489.993 221.421 492.691 225.657C495.3 229.754 496.064 234.002 493.96 239.004C491.777 244.192 486.432 250.372 476.225 257.885C466.059 265.368 451.269 274.031 430.503 284.256Z"
        stroke="currentColor"
        className="text-blue-200"
        strokeWidth="4"
      />
    </svg>
    <svg
      width="328"
      height="406"
      viewBox="0 0 328 406"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="hidden lg:block"
    >
      <path
        opacity="0.3"
        d="M633.78 364.637C572.898 394.613 495.324 405.763 415.408 402.893C335.513 400.024 253.439 383.147 183.66 357.223C113.822 331.278 56.554 296.365 26.0064 257.598C10.7546 238.243 2.24609 218.035 2.01717 197.566C1.78861 177.123 9.81477 156.145 28.1495 135.211C65.1106 93.0094 101.187 71.3332 136.751 60.4636C172.367 49.5785 207.607 49.4834 243.052 50.677C247.746 50.8352 252.444 51.0161 257.146 51.1972C323.36 53.7476 390.296 56.3259 459.455 -4.50149C532.142 -68.433 615.421 -61.837 666.441 -24.1813C691.971 -5.33885 709.383 21.2388 713.485 50.6465C717.58 80.0075 708.445 112.442 680.41 143.14C666.094 158.816 658.31 172.344 655.372 184.292C652.415 196.315 654.401 206.594 659.324 215.604C664.209 224.545 671.951 232.178 680.418 239.076C685.782 243.447 691.568 247.62 697.161 251.654C700.346 253.951 703.469 256.204 706.416 258.422C714.665 264.631 721.629 270.632 725.7 277.026C729.683 283.28 730.876 289.858 727.642 297.545C724.33 305.419 716.296 314.64 701.226 325.732C686.197 336.795 664.371 349.575 633.78 364.637Z"
        stroke="currentColor"
        className="text-blue-200"
        strokeWidth="4"
      />
    </svg>
  </>
);

export { TopRightLine, TopRightLine2 };
