import React from 'react';
import clsx from 'clsx';
import { Logo, Grid } from '../..';

export interface Props {
  className?: string;
}

const Header = ({ className, ...props }: Props) => {
  return (
    <header
      {...props}
      data-testid="header"
      className={clsx('border-b border-grey-300 py-5 sm:pt-6 sm:pb-5 sticky top-0', className)}
    >
      <Grid>
        <a href="https://www.homeppl.com/guarantid/" className="block col-span-2">
          <Logo />
        </a>
      </Grid>
    </header>
  );
};

export default Header;
