import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';
import { useEffect, useState, createContext, ReactElement } from 'react';
import { getSegmentKeyEnv } from 'src/utils/envHelpers';

export const AnalyticsContext = createContext<
  Partial<{
    analytics: Analytics;
    setUserIdentity: (v: string) => void;
  }>
>({ analytics: undefined, setUserIdentity: undefined });

const AnalyticsProvider = ({ children, path }: { children: ReactElement; path: string }) => {
  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined);
  const [userIdentity, setUserIdentity] = useState<null | string>(null);

  useEffect(() => {
    const loadAnalytics = async () => {
      const res = getSegmentKeyEnv();
      if (res.type === 'Ok') {
        const [response] = await AnalyticsBrowser.load({ writeKey: res.value });
        setAnalytics(response);
      }
    };
    loadAnalytics();
  }, []);

  useEffect(() => {
    if (analytics) analytics.page(path);
  }, [path, analytics]);

  useEffect(() => {
    if (userIdentity && analytics) analytics.identify(userIdentity);
  }, [userIdentity, analytics]);

  return (
    <AnalyticsContext.Provider value={{ analytics, setUserIdentity }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
