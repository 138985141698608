import { IconProps } from './types';
import clsx from 'clsx';

const ChevronDown = ({ className, strokeClasses }: IconProps) => (
  <svg
    className={clsx('h-5 w-5 overflow-hidden', className)}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 9L12 17L20 9"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={clsx('', strokeClasses)}
    />
  </svg>
);

export default ChevronDown;
