import clsx from 'clsx';
import React, { ReactElement } from 'react';

export interface TextStepProps {
  title: string;
  step: string | ReactElement;
}

const TextStep = ({ title, step }: TextStepProps) => (
  <div className="flex items-center mt-6 relative h-20">
    {typeof step === 'string' ? (
      <div className="font-extrabold absolute -top-4 left-0 text-[72px] text-blue stroke-blue-200 outline-stroke pointer-events-none select-none">
        {step}
      </div>
    ) : (
      step
    )}
    <p
      className={clsx(
        'text-lg font-medium left-4  text-grey-900',
        typeof step === 'string' ? 'absolute' : 'relative',
      )}
    >
      {title}
    </p>
  </div>
);

export default TextStep;
