import { IconProps } from './types';
import clsx from 'clsx';

const BadgeCheck = ({ className, strokeClasses }: IconProps) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    className={clsx('h-5 w-5', className)}
  >
    <path
      d="M11.9998 16L14.6665 18.6667L19.9998 13.3334M10.4465 6.26271C11.4029 6.18638 12.3108 5.81031 13.0411 5.18805C13.8663 4.4844 14.9153 4.0979 15.9998 4.0979C17.0843 4.0979 18.1333 4.4844 18.9585 5.18805C19.6888 5.81031 20.5967 6.18638 21.5531 6.26271C22.6341 6.34871 23.6491 6.81714 24.4159 7.58394C25.1827 8.35074 25.6511 9.36571 25.7371 10.4467C25.8135 11.4031 26.1895 12.3111 26.8118 13.0414C27.5154 13.8666 27.9019 14.9156 27.9019 16C27.9019 17.0845 27.5154 18.1335 26.8118 18.9587C26.1895 19.689 25.8135 20.597 25.7371 21.5534C25.6511 22.6344 25.1827 23.6493 24.4159 24.4161C23.6491 25.1829 22.6341 25.6514 21.5531 25.7374C20.5967 25.8137 19.6888 26.1898 18.9585 26.812C18.1333 27.5157 17.0843 27.9022 15.9998 27.9022C14.9153 27.9022 13.8663 27.5157 13.0411 26.812C12.3108 26.1898 11.4029 25.8137 10.4465 25.7374C9.36547 25.6514 8.3505 25.1829 7.5837 24.4161C6.8169 23.6493 6.34847 22.6344 6.26247 21.5534C6.18613 20.597 5.81007 19.689 5.1878 18.9587C4.48416 18.1335 4.09766 17.0845 4.09766 16C4.09766 14.9156 4.48416 13.8666 5.1878 13.0414C5.81007 12.3111 6.18613 11.4031 6.26247 10.4467C6.34847 9.36571 6.8169 8.35074 7.5837 7.58394C8.3505 6.81714 9.36547 6.34871 10.4465 6.26271Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={clsx('', strokeClasses)}
    />
  </svg>
);

export default BadgeCheck;
