/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, createContext, useState, useEffect } from 'react';
import { StringMap } from 'src/utils/types';

export interface StripeData {
  product?: string;
  price?: string;
  customer?: string;
  invoiceItem?: string;
  invoice?: string;
}

export enum AppStateKeys {
  STRIPE_DATA = 'STRIPE_DATA',
  IS_SUBMITTED = 'IS_SUBMITTED',
}

type AppContextPaylaod = [StringMap<any>, (value: any) => void];
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const AppStateContext = createContext<AppContextPaylaod>([{}, () => {}]);

export const AppStateContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [value, setValue] = useState({});

  return <AppStateContext.Provider value={[value, setValue]}>{children}</AppStateContext.Provider>;
};

export const useAppState = <T,>(key: AppStateKeys, initialValue: T) => {
  const [value, setValue] = useContext(AppStateContext);

  useEffect(() => {
    if (!Object.keys(value).includes(key)) {
      setValue((v) => ({ ...v, [key]: initialValue }));
    }
  }, []);

  const updateValue = (values: T) => {
    setValue((v) => ({ ...v, [key]: values }));
  };

  return [value[key] as T, updateValue] as const;
};
