import { IconProps } from './types';

const Buildings = ({ className }: IconProps) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6667 4.29995C11.5694 4.29995 11.4762 4.33858 11.4074 4.40735C11.3386 4.47611 11.3 4.56937 11.3 4.66662V11.3352L15.9667 16.0019V23.7H23.7V4.66662C23.7 4.56937 23.6614 4.47611 23.5926 4.40735C23.5239 4.33858 23.4306 4.29995 23.3333 4.29995H11.6667ZM14.3667 23.7V16.6647L9.33335 11.6313L4.30001 16.6647V23.7H8.53335V19.5H10.1333V23.7H14.3667ZM9.70001 9.73525L9.33335 9.36858L2.70001 16.0019V25.2999H25.3V4.66662C25.3 4.14502 25.0928 3.6448 24.724 3.27597C24.3552 2.90715 23.8549 2.69995 23.3333 2.69995H11.6667C11.1451 2.69995 10.6449 2.90715 10.276 3.27597C9.90721 3.6448 9.70001 4.14503 9.70001 4.66662V9.73525Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1667 7.3667C15.6085 7.3667 15.9667 7.72487 15.9667 8.1667V8.1772C15.9667 8.61903 15.6085 8.9772 15.1667 8.9772C14.7249 8.9772 14.3667 8.61903 14.3667 8.1772V8.1667C14.3667 7.72487 14.7249 7.3667 15.1667 7.3667Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8333 7.3667C20.2752 7.3667 20.6333 7.72487 20.6333 8.1667V8.1772C20.6333 8.61903 20.2752 8.9772 19.8333 8.9772C19.3915 8.9772 19.0333 8.61903 19.0333 8.1772V8.1667C19.0333 7.72487 19.3915 7.3667 19.8333 7.3667Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8333 12.0332C20.2752 12.0332 20.6333 12.3914 20.6333 12.8332V12.8437C20.6333 13.2855 20.2752 13.6437 19.8333 13.6437C19.3915 13.6437 19.0333 13.2855 19.0333 12.8437V12.8332C19.0333 12.3914 19.3915 12.0332 19.8333 12.0332Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8333 16.7C20.2752 16.7 20.6333 17.0581 20.6333 17.5V17.5105C20.6333 17.9523 20.2752 18.3105 19.8333 18.3105C19.3915 18.3105 19.0333 17.9523 19.0333 17.5105V17.5C19.0333 17.0581 19.3915 16.7 19.8333 16.7Z"
      fill="#currentColor"
    />
  </svg>
);

export default Buildings;
