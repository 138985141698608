import { ElementType, HTMLAttributes } from 'react';
import clsx from 'clsx';

export interface Props extends HTMLAttributes<HTMLElement> {
  className?: string;
  children?: React.ReactNode;
  as?: ElementType;
}

const Grid = ({ children, className, as: Component = 'div', ...props }: Props) => {
  return (
    <Component
      data-testid="grid"
      {...props}
      className={clsx(
        'grid grid-cols-4 gap-x-5 px-5 sm:grid-cols-8 lg:grid-cols-12 lg:gap-x-6 lg:px-6 max-w-screen-xl m-auto',
        className,
      )}
    >
      {children}
    </Component>
  );
};

export default Grid;
