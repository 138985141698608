import { mkError, mkOk } from './types';

type Env = {
  SENTRY_DSN: string;
  APP_ENVIRONMENT: string;
  SENTRY_AUTH_TOKEN: string;
  BASE_URL: string;
  STRIPE_API_KEY: string;
  NEXT_PUBLIC_GUARANTID_USER_STORE_URL: string;
};

const getContext = () => process.env.CONTEXT as 'production' | 'branch-deploy' | 'deploy-preview';

export const getEnv = (key: keyof Env) => {
  const context = getContext();
  const prefix = (context || '').replace('-', '_');
  const prefixedEnv = process.env[`${prefix}_${key}`];
  const env = process.env[key];
  if ([prefixedEnv, env].some((v) => typeof v === 'string')) return mkOk(prefixedEnv || env || '');
  return mkError(`Missing env: ${key}`);
};

export const getSegmentKeyEnv = () => {
  const segmentKey = process.env.SEGMENT_KEY || process.env.NEXT_PUBLIC_SEGMENT_KEY;

  if (segmentKey) return mkOk(segmentKey);
  return mkError('Missing env: SEGMENT_KEY');
};
