// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  enabled: process.env.APP_ENVIRONMENT !== 'local',
  dsn: SENTRY_DSN,
  maxBreadcrumbs: 50,
  environment: process.env.APP_ENVIRONMENT,
  attachStacktrace: true,
  tracesSampleRate: 1.0,
  release: process.env.NEXT_APP_RELEASE,
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb?.category?.startsWith('ui')) {
      const target = hint?.event?.target;
      breadcrumb.message =
        target.id || target.dataset.testid
          ? `${target.tagName.toLowerCase()}[@id="${target.id}"][@data-testid="${
              target.dataset.testid
            }"]`
          : breadcrumb.message;
    }
    return breadcrumb;
  },
});
