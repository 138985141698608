import React from 'react';
import clsx from 'clsx';

interface Props {
  disabled?: boolean;
  count?: number;
  selected: boolean;
  onClick: () => void;
  label: string;
  value: string;
  Icon?: React.ReactNode;
}

const AddressOption = (
  { label, selected, onClick, disabled, count, Icon, ...props }: Props,
  ref,
) => (
  <li
    role="option"
    aria-selected={selected}
    aria-label={label}
    ref={ref}
    className={clsx(
      count ? 'h-18' : 'h-9',
      'w-full px-4 py-2 text-sm leading-5',
      'cursor-pointer list-none',
      selected ? 'text-blue-500 font-bold' : 'text-black',
      !selected && !disabled && 'hover:bg-blue',
      'focus:outline-none',
    )}
    onClick={(e) => {
      e.preventDefault();
      onClick();
    }}
    {...props}
  >
    <div className="truncate w-full">{label}</div>
    {count && <p className="font-semibold">{count}</p>}
  </li>
);

export default React.forwardRef(AddressOption);
